import React, { useState, useEffect } from "react";
import { encodeToken, decodeToken } from "./tokenUtils"; // Import utilities

function InviteCreate({ navigate }) {
  const [email, setEmail] = useState("");
  const [inviteLink, setInviteLink] = useState("");
  const [isAdmin, setIsAdmin] = useState(false); // State for the isAdmin checkbox

  useEffect(() => {
    // Check if the current user has a valid admin token
    const storedToken = localStorage.getItem("inviteToken");
    if (storedToken) {
      const userInfo = decodeToken(storedToken);
      if (userInfo && userInfo.isAdmin) {
        // User is admin, allow access to create invitations
        return;
      }
    }
    // If no valid admin token, redirect to unauthorized
    navigate("/unauthorized");
  }, [navigate]);

  const handleInvite = () => {
    if (!email) {
      alert("Please enter an email address.");
      return;
    }

    // Generate the token using utility function with admin flag
    const token = encodeToken(email, isAdmin);

    // Generate the invite link
    const link = `${window.location.origin}/invite?token=${encodeURIComponent(
      token
    )}`;
    setInviteLink(link);
  };

  return (
    <div className="auth">
      <h1>Create Invitation</h1>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
      />
      <div>
        <input
          type="checkbox"
          checked={isAdmin}
          onChange={(e) => setIsAdmin(e.target.checked)}
        />
        <label>Grant admin privileges to this user</label>
      </div>
      <button onClick={handleInvite}>Generate Invite Link</button>
      {inviteLink && (
        <div>
          <h2>Invite Link:</h2>
          <a href={inviteLink}>{inviteLink}</a>
        </div>
      )}
    </div>
  );
}

export default InviteCreate;
