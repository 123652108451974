// PartstownMarkups.js
import React, { useState } from "react";
import vendorsData from "../data/partstown.json";
import "../styles/ReviewMarkups.css";
import ReviewMarkupsBase from "../components/ReviewMarkupsBase";
import useMarkups from "../components/useMarkups";
import markupData from "../data/markups";

const pre = (c) => "review-markups__" + c;

const PartstownMarkups = () => {
  const [sortByDiscount, setSortByDiscount] = useState(true);
  const [showPricing, setShowPricing] = useState(true);
  const { calcEndUserMarkup, calcDealerMarkup, calcWholesalerMarkup } =
    useMarkups(markupData);

  // Sort vendors based on discount if checkbox is checked
  const vendors = sortByDiscount
    ? [...vendorsData].sort((a, b) => a.Discount - b.Discount)
    : vendorsData;

  const columns = ["Vendor", "Discount", "End User", "Dealer", "Wholesaler"];

  // Helper function to get either the markup or the price
  const getMarkupOrPrice = (discount, standardMarkup, showPricing) => {
    const ListPrice = 100; // Fixed price for the item
    const cost = ListPrice * (1 - discount); // Discounted cost
    const price = cost * standardMarkup; // Final price with markup
    return showPricing ? price.toFixed(2) : standardMarkup.toFixed(2);
  };

  // Render Vendor Row
  const renderVendorRow = (vendor) => {
    const discount = vendor.Discount;
    const endUserMarkup = calcEndUserMarkup(discount);
    const dealerMarkup = calcDealerMarkup(discount);
    const wholesalerMarkup = calcWholesalerMarkup(discount);

    return (
      <div className={pre("row")} key={vendor.Vendor}>
        <div>{vendor.Vendor}</div>
        <div>{(discount * 100).toFixed(2)}%</div>
        <div>{getMarkupOrPrice(discount, endUserMarkup, showPricing)}</div>
        <div>{getMarkupOrPrice(discount, dealerMarkup, showPricing)}</div>
        <div>{getMarkupOrPrice(discount, wholesalerMarkup, showPricing)}</div>
      </div>
    );
  };

  const checkboxes = [
    {
      label: "Show pricing for $100 item",
      checked: showPricing,
      onChange: (e) => setShowPricing(e.target.checked),
    },
    {
      label: "Sort by Discount",
      checked: sortByDiscount,
      onChange: (e) => setSortByDiscount(e.target.checked),
    },
  ];

  const handleContainerDoubleClick = () => {
    setShowPricing((prev) => !prev);
  };

  return (
    <ReviewMarkupsBase
      title="Review Partstown Markups"
      returnUrl="/?pt=true"
      data={vendors}
      columns={columns}
      renderVendorRow={renderVendorRow}
      checkboxes={checkboxes}
      containerClass="partstown"
      onContainerDoubleClick={handleContainerDoubleClick}
    />
  );
};

export default PartstownMarkups;
