import React, { useState, useEffect } from "react";
import { decodeToken } from "./tokenUtils"; // Import utilities

function InviteAccept({ token, navigate }) {
  const [email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const TOKEN_VALIDITY_MINUTES = 15; // Set token validity duration for accepting invites

  useEffect(() => {
    // Validate the token for expiration immediately
    const userInfo = decodeToken(token);
    if (userInfo) {
      const tokenTime = new Date(userInfo.timestamp);
      const currentTime = new Date();
      const timeDiff = (currentTime - tokenTime) / (1000 * 60); // Time difference in minutes

      if (timeDiff > TOKEN_VALIDITY_MINUTES) {
        setValidationMessage("Invite link has expired.");
      }
    } else {
      setValidationMessage("Invalid token.");
    }
  }, [token]);

  const handleValidation = () => {
    const userInfo = decodeToken(token);
    if (userInfo && email === userInfo.email) {
      // Store token in local storage
      localStorage.setItem("inviteToken", token);
      setValidationMessage("Token validated and stored successfully!");
      navigate("/");
    } else {
      setValidationMessage("Invalid email address.");
    }
  };

  return (
    <div className="auth">
      <h1>Accept Invitation</h1>
      {validationMessage ? (
        <p>{validationMessage}</p>
      ) : (
        <>
          <p>Please enter your email address to validate the invite:</p>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
          />
          <button onClick={handleValidation}>Validate Invite</button>
        </>
      )}
    </div>
  );
}

export default InviteAccept;
