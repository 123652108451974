// DisplayPrices.jsx
import React from "react";
import { formatCurrency } from "./pricingHelpers";
import "../styles/PartPricingStyles.css";
import toast, { Toaster } from "react-hot-toast";

const DisplayPrices = ({ prices, tier }) => {
  if (!prices) return null;

  const tierLower = tier?.toLowerCase();
  const highlightClass = (label) =>
    tierLower && label.toLowerCase().startsWith(tierLower)
      ? pre("highlight")
      : "";

  const handleCopy = (tier) => {
    navigator.clipboard.writeText(prices[tier]);
    toast.success(`Copied $${prices[tier]}`, {
      position: "bottom-center",
      icon: "📄",
      style: { backgroundColor: "var(--neutral-6)", color: "white" },
    });
  };

  const pre = (c) => "part-pricing__" + c;

  return (
    <div className={pre("results")}>
      <Toaster />
      <h3>Customer Prices</h3>
      <div className={pre("result-prices")}>
        <div
          className={`${pre("label")} ${highlightClass("EndUser")}`}
          onClick={() => handleCopy("endUserPrice")}
        >
          End User
        </div>
        <div
          className={`${pre("label")} ${highlightClass("Dealer")}`}
          onClick={() => handleCopy("dealerPrice")}
        >
          Dealer
        </div>
        <div
          className={`${pre("label")} ${highlightClass("Wholesaler")}`}
          onClick={() => handleCopy("wholesalerPrice")}
        >
          Wholesaler
        </div>
        <div
          className={`${pre("price")} ${highlightClass("EndUser")}`}
          onClick={() => handleCopy("endUserPrice")}
        >
          {formatCurrency(prices.endUserPrice)}
        </div>
        <div
          className={`${pre("price")} ${highlightClass("Dealer")}`}
          onClick={() => handleCopy("dealerPrice")}
        >
          {formatCurrency(prices.dealerPrice)}
        </div>
        <div
          className={`${pre("price")} ${highlightClass("Wholesaler")}`}
          onClick={() => handleCopy("wholesalerPrice")}
        >
          {formatCurrency(prices.wholesalerPrice)}
        </div>
      </div>
    </div>
  );
};

export default DisplayPrices;
