// VendorSelect.jsx
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#dfdfdf",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#707070",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f6f6f6" : "#fff",
    color: "#2d2d2d",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    },
  }),
};

const VendorSelect = ({ vendors, onChange, search }) => {
  const [searchString, setSearchString] = useState(search || "");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    setSearchString(search || "");
    if (search) {
      setMenuIsOpen(true);
    }
  }, [search]);

  const handleInputChange = (value, { action }) => {
    console.log("handleInputChange", value, action);
    setSearchString(value);
    setMenuIsOpen(!!value);
  };

  const handleChange = (selectedOption, { action }) => {
    console.log("handleChange", selectedOption, action);
    onChange(selectedOption);
    if (!selectedOption) {
      setMenuIsOpen(false);
    }
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  return (
    <Select
      options={vendors.map((vendor) => ({
        label: vendor.Vendor,
        value: vendor,
      }))}
      components={{ DropdownIndicator }}
      onChange={handleChange}
      isClearable
      placeholder="Start typing to search..."
      inputValue={searchString}
      onInputChange={handleInputChange}
      styles={customStyles}
      classNamePrefix={"react-select"}
      menuIsOpen={menuIsOpen}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
};

VendorSelect.propTypes = {
  vendors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  search: PropTypes.string,
};

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  const rotation = selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <components.DropdownIndicator {...props}>
      <div
        style={{
          transform: rotation,
          transition: "transform 0.3s ease",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 20 20"
        >
          <path
            fill="none" // No fill, just a stroke
            stroke="currentColor" // Use the current text color for the stroke
            strokeWidth="1.5" // Set stroke-width as desired
            d="M10.103 12.778L16.81 6.08a.69.69 0 0 1 .99.012a.726.726 0 0 1-.012 1.012l-7.203 7.193a.69.69 0 0 1-.985-.006L2.205 6.72a.727.727 0 0 1 0-1.01a.69.69 0 0 1 .99 0z"
          />
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

export default VendorSelect;
