import React from "react";
import CanvasJSReact from "../lib/canvasjs.react";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MarkupChart = ({ markups }) => {
  console.log("MarkupChart", markups);
  const dataPointsEndUser = markups.map((point) => ({
    x: point.discount * 100,
    y: point.endUserMarkup,
  }));

  const dataPointsDealer = markups.map((point) => ({
    x: point.discount * 100,
    y: point.dealerMarkup,
  }));

  const dataPointsWholesaler = markups.map((point) => ({
    x: point.discount * 100,
    y: point.wholesalerMarkup,
  }));

  const options = {
    theme: "light2",
    title: {
      text: "Discount vs Markup",
    },
    axisX: {
      title: "Discount",
      suffix: "%",
      interval: 10,
      minimum: -1,
      maximum: 101,
    },
    axisY: {
      title: "Markup",
      logarithmic: true,
      minimum: 1,
      maximum: 3.1,
    },
    data: [
      {
        type: "line",
        name: "EndUser",
        showInLegend: true,
        dataPoints: dataPointsEndUser,
      },
      {
        type: "line",
        name: "Dealer",
        showInLegend: true,
        dataPoints: dataPointsDealer,
      },
      {
        type: "line",
        name: "Wholesaler",
        showInLegend: true,
        dataPoints: dataPointsWholesaler,
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default MarkupChart;
