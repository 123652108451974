const vendors = [
  {
    Vendor: "ADMIRAL CRAFT",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "ANTHONY",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "ARDCO",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "ARCTIC AIR / COAST APPLIANCE",
    MinOrder: null,
    AvgDiscount: 0.2841,
    EnduserMarkup: 1.56,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "ARCTIC AIR / BROICH",
    MinOrder: null,
    AvgDiscount: 1 / 3,
    EnduserMarkup: 1.5,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "ARCTIC AIR / ENCOMPASS AKA DAYTON",
    MinOrder: null,
    AvgDiscount: 0.396,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "ARCTIC COOLING SYSTEM",
    MinOrder: null,
    AvgDiscount: 0.2,
    EnduserMarkup: 1.5,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "ATOSA",
    MinOrder: null,
    AvgDiscount: 0.6,
    EnduserMarkup: 2.5,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "AVANTCO/CLARK SERVICE GROUP",
    MinOrder: null,
    AvgDiscount: 0.2,
    EnduserMarkup: 1.35,
    DealerMarkup: 1.25,
    WholesalerMarkup: 1.2,
  },
  {
    Vendor: "BALLY",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "BASTIAN BLESSING/EMCO",
    MinOrder: null,
    Discount: 0.25,
    EnduserMarkup: 1.5,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "BEVERAGE AIR",
    MinOrder: null,
    Discount: 0.55,
    EnduserMarkup: 1 / 0.45,
    DealerMarkup: 1.55,
    WholesalerMarkup: 1.4,
  },
  {
    Vendor: "BEVERAGE AIR DROPSHIP",
    MinOrder: "$200",
    Discount: 0.55,
    EnduserMarkup: 1 / 0.45,
    DealerMarkup: 1.55,
    WholesalerMarkup: 1.4,
  },
  {
    Vendor: "BLUE AIR",
    MinOrder: null,
    AvgDiscount: 0.73,
    EnduserMarkup: 1 / 0.27,
    DealerMarkup: 1.75,
    WholesalerMarkup: 1.55,
  },
  {
    Vendor: "BOHN / HEATCRAFT / THOMPSON SUPPY",
    MinOrder: null,
    AvgDiscount: 1 - 0.51063,
    EnduserMarkup: 1 / 0.51063,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "COMMERCIAL REFRIGERATION COMPANY",
    MinOrder: null,
    AvgDiscount: 0.3,
    EnduserMarkup: 1.5,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "COMPONENT HARDWARE GROUP",
    MinOrder: null,
    AvgDiscount: 0.475,
    EnduserMarkup: 1 / (1 - 0.475),
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "CONTINENTAL",
    MinOrder: null,
    AvgDiscount: 0.4,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.4,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "ELECTRO FREEZE / F.E.A. SERVICE",
    MinOrder: null,
    AvgDiscount: 0.3,
    EnduserMarkup: 1.6,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "ENTRÉE / HAGAR PARTS",
    MinOrder: null,
    AvgDiscount: 0.25,
    EnduserMarkup: 1.43,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "EVEREST",
    MinOrder: null,
    AvgDiscount: 0.65,
    EnduserMarkup: 1 / (1 - 0.65),
    DealerMarkup: 1.65,
    WholesalerMarkup: 1.45,
  },
  {
    Vendor: "FOGEL USA",
    MinOrder: "$20.00",
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "FOLLETT",
    MinOrder: null,
    AvgDiscount: 0.19,
    EnduserMarkup: 1.32,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "FOSTER",
    MinOrder: null,
    Discount: 0.25,
    EnduserMarkup: 1.56,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "FRANK DOOR",
    MinOrder: "$25",
    AvgDiscount: 0.2,
    EnduserMarkup: 1.45,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "FRIGOGLAS/STAJAC INDUSTRIES",
    MinOrder: null,
    AvgDiscount: 0.35,
    EnduserMarkup: 1 / 0.65,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "GLASTENDER",
    MinOrder: null,
    Discount: 0.55,
    EnduserMarkup: 1 / 0.45,
    DealerMarkup: 0.75 / 0.45,
    WholesalerMarkup: 0.7 / 0.45,
  },
  {
    Vendor: "GLOBAL/KELVINATOR",
    MinOrder: null,
    Discount: 0.5725,
    EnduserMarkup: 1 / (1 - 0.5725),
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "GLOBAL/KELVINATOR DROPSHIPS",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "HK INTERNATIONAL",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "HOBART",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "HOBART / DROP SHIPMENTS",
    MinOrder: null,
    Discount: 0.44,
    EnduserMarkup: 1 / 0.56,
    DealerMarkup: 1.45,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "HOSHIZAKI WESTERN",
    MinOrder: null,
    AvgDiscount: 0.15,
    EnduserMarkup: 1.45,
    DealerMarkup: 1.25,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "HOWARD/McCRAY",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "HTPG  AKA RUSSELL / WITT (FOR PARTS ORDERS ONLY)",
    MinOrder: "$50",
    Discount: 0.692,
    EnduserMarkup: 1 / (1 - 0.692),
    DealerMarkup: 1.65,
    WholesalerMarkup: 1.5,
  },
  {
    Vendor: "HTPG  AKA RUSSELL / WITT (FOR EQUIPMENT ONLY)",
    MinOrder: "$50",
    Discount: 0.79,
    EnduserMarkup: 1 / (1 - 0.79),
    DealerMarkup: 1.95,
    WholesalerMarkup: 1.65,
  },
  {
    Vendor: "HUSSMANN / OEM",
    MinOrder: null,
    AvgDiscount: 0.055059 /* https://caseparts.slack.com/archives/C07LT5PLKFD/p1725997968912339 */,
    EnduserMarkup: 1.43,
    DealerMarkup: 1.3,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "HUSSMANN/FAZIO",
    MinOrder: null,
    AvgDiscount: 0.19618 /* https://caseparts.slack.com/archives/C07LT5PLKFD/p1725997968912339 */,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.45,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "IKON / MVP GROUP",
    MinOrder: "$50",
    AvgDiscount: 0.65,
    EnduserMarkup: 1 / (1 - 0.65),
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "INFRICO USA",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "INTERNATIONAL COLD STORAGE / EVERIDGE",
    MinOrder: null,
    AvgDiscount: 1 - 0.8447,
    EnduserMarkup: 1.32,
    DealerMarkup: 1.2,
    WholesalerMarkup: 1.15,
  },
  {
    Vendor: "JAMISON DOOR COMPANY",
    MinOrder: null,
    AvgDiscount: 0.15,
    EnduserMarkup: 1.4,
    DealerMarkup: 1.25,
    WholesalerMarkup: 1.2,
  },
  {
    Vendor: "KAIRAK",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "KAIRAK / DROP SHIPMENTS",
    MinOrder: null,
    Discount: 0.44,
    EnduserMarkup: 1 / (1 - 0.44),
    DealerMarkup: 1.45,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "KASON",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.2,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.45,
  },
  {
    Vendor: "KELVINATOR / GLOBAL",
    MinOrder: null,
    Discount: 0.5725,
    EnduserMarkup: 2.34,
    DealerMarkup: 1.75,
    WholesalerMarkup: 1.58,
  },
  {
    Vendor: "KELVINATOR / GLOBAL DROP SHIPMENTS",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "KOLPAK",
    MinOrder: "$100 - and below min they add an extra $25",
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "KRACK COIL / FAZIO",
    MinOrder: null,
    AvgDiscount: 0.3,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.4,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "KYSOR PANEL / KPS GLOBAL",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "KYSOR / WARREN",
    MinOrder: null,
    AvgDiscount: 1 - 0.35715,
    EnduserMarkup: 2.8,
    DealerMarkup: 1.55,
    WholesalerMarkup: 1.4,
  },
  {
    Vendor: "LEER/STAR STARRETT",
    MinOrder: null,
    Discount: 0.25,
    EnduserMarkup: 1.55,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.28,
  },
  {
    Vendor: "MARVEL  aka AGA Marvel",
    MinOrder: null,
    EnduserMarkup: 1.67,
    DealerMarkup: 1.4,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "MICRO MATIC USA",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "MIGALI",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "MONTAGUE",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "NELSON  AKA  C. NELSON",
    MinOrder: null,
    AvgDiscount: 0.55,
    EnduserMarkup: 1 / 0.45,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "NORRIS",
    MinOrder: null,
    AvgDiscount: 0.4,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.4,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "NORTHLAND",
    MinOrder: null,
    AvgDiscount: 0.4,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "OMNI TEMP/TEAM",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "PEERLESS",
    MinOrder: "$100",
    AvgDiscount: 0.36,
    EnduserMarkup: 1.73,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.51,
  },
  {
    Vendor: "RUSSELL / WITT (FOR PARTS ORDERS ONLY)",
    MinOrder: "$50",
    Discount: 0.692,
    EnduserMarkup: 3.18,
    DealerMarkup: 1.65,
    WholesalerMarkup: 1.5,
  },
  {
    Vendor: "RUSSELL / WITT (FOR EQUIPMENT ONLY)",
    MinOrder: "$50",
    Discount: 0.77,
    EnduserMarkup: 4.26,
    DealerMarkup: 2.61,
    WholesalerMarkup: 2.39,
  },
  {
    Vendor: "SILVER KING/3WIRE",
    MinOrder: null,
    Discount: 0.3,
    EnduserMarkup: 1 / 0.7,
    DealerMarkup: 1.25,
    WholesalerMarkup: 1.2,
  },
  {
    Vendor: "SILVER KING/MARMONLINK",
    MinOrder: null,
    Discount: 0.25,
    EnduserMarkup: 1.6,
    DealerMarkup: 1.4,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "SILVER KING/PMA",
    MinOrder: null,
    Discount: 1 - 0.619,
    EnduserMarkup: 1.62,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "SPARTAN (JMCFOODEQUIPMENT)",
    MinOrder: null,
    Discount: 0.2,
    EnduserMarkup: 1.34,
    DealerMarkup: 1.25,
    WholesalerMarkup: 1.2,
  },
  {
    Vendor: "STYLELINE  SYSTEMS/ COMMERCIAL REFRIG",
    MinOrder: null,
    AvgDiscount: 0.4,
    EnduserMarkup: 5 / 3,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "THERMALRITE/EVERIDGE",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "THERMO-KOOL",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "TRAULSEN",
    MinOrder: null,
    Discount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "TRAULSEN / DROP SHIPMENTS",
    MinOrder: null,
    Discount: 0.44,
    EnduserMarkup: 1 / (1 - 0.44),
    DealerMarkup: 1.45,
    WholesalerMarkup: 1.3,
  },
  {
    Vendor: "TRUE MFG",
    MinOrder: null,
    AvgDiscount: 0.595,
    EnduserMarkup: 1 / (1 - 0.595),
    DealerMarkup: 1.55,
    WholesalerMarkup: 1.4,
  },
  {
    Vendor: "TURBO AIR",
    MinOrder: null,
    Discount: 0.7,
    EnduserMarkup: 8 / 3,
    DealerMarkup: 5 / 3,
    WholesalerMarkup: 1.5,
  },
  {
    Vendor: "U-LINE",
    MinOrder: null,
    AvgDiscount: 0.25,
    EnduserMarkup: 1.69,
    DealerMarkup: 1.35,
    WholesalerMarkup: 1.25,
  },
  {
    Vendor: "UTILITY",
    MinOrder: null,
    AvgDiscount: 0.5,
    EnduserMarkup: 2.0,
    DealerMarkup: 1.5,
    WholesalerMarkup: 1.35,
  },
  {
    Vendor: "VICTORY",
    MinOrder: null,
    Discount: 0.55,
    EnduserMarkup: 1 / (1 - 0.55),
    DealerMarkup: 1.55,
    WholesalerMarkup: 1.45,
  },
  {
    Vendor: "ZERO ZONE",
    MinOrder: null,
    AvgDiscount: 0.495,
    EnduserMarkup: 1 / (1 - 0.495),
    DealerMarkup: 1.65,
    WholesalerMarkup: 1.6,
  },
];

export default vendors;
