// tokenUtils.js

// Generate a simple CRC checksum
export const generateCRC = (str) => {
  let crc = 0;
  for (let i = 0; i < str.length; i++) {
    crc += str.charCodeAt(i);
  }
  return crc;
};

// Encode data with CRC into a token
export const encodeToken = (email, isAdmin = false) => {
  const currentTime = new Date().toISOString();
  const adminFlag = isAdmin ? "1" : "0"; // 1 for true (admin), 0 for false (non-admin)
  const tokenData = `${email}|${currentTime}|${adminFlag}`; // Include admin flag
  const crc = generateCRC(tokenData); // Generate CRC checksum
  const tokenWithCRC = `${tokenData}|${crc}`;
  console.log("encodeToken", email, currentTime, crc, tokenWithCRC);
  return btoa(tokenWithCRC); // Encode using base64
};

// Decode and validate token, returns { email, timestamp, isAdmin } if valid, null otherwise
export const decodeToken = (token) => {
  console.log("decodeToken", token);
  try {
    const decoded = atob(token);
    const [email, timestamp, adminFlag, crc] = decoded.split("|"); // Include admin flag
    const tokenData = `${email}|${timestamp}|${adminFlag}`;
    const expectedCRC = generateCRC(tokenData);
    console.log(email, timestamp, adminFlag, crc, expectedCRC);
    if (parseInt(crc, 10) === expectedCRC) {
      const isAdmin = adminFlag === "1"; // Convert flag to boolean
      return { email, timestamp, isAdmin };
    }
  } catch (error) {
    // Handle decoding or parsing error
    console.error("Error decoding token:", error);
  }
  return null;
};
