import React, { useState, useEffect, useRef } from "react";
import "../styles/ListPriceModal.css";

const ListPriceModal = ({ isOpen, onClose, onSubmit, initialPrice }) => {
  const [listPrice, setListPrice] = useState(initialPrice || "");
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialPrice) {
      setListPrice(initialPrice);
    }
  }, [initialPrice]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus(); // Set focus to the input field when modal is opened
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(listPrice);
    onClose();
  };

  const handleDelete = () => {
    setListPrice("");
    onSubmit(0); // Return 0 to signal clearing the override
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Enter List Price</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="number"
            value={listPrice}
            onChange={(e) => setListPrice(e.target.value)}
            // placeholder="Enter List Price"
            ref={inputRef} // Attach the ref to the input field
          />
          <div className="modal-buttons">
            <button type="submit">Submit</button>
            {listPrice && (
              <button type="button" onClick={handleDelete}>
                Delete
              </button>
            )}
            <button type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ListPriceModal;
