// VendorMarkups.js
import React, { useState } from "react";
import vendorsData from "../data/vendors";
import "../styles/ReviewMarkups.css";
import useMarkups from "../components/useMarkups";
import markupData from "../data/markups";
import ReviewMarkupsBase from "../components/ReviewMarkupsBase";

const pre = (c) => "review-markups__" + c;

const VendorMarkups = () => {
  const [sortByDiscount, setSortByDiscount] = useState(true);
  const [showPricing, setShowPricing] = useState(true);
  const [showVariance, setShowVariance] = useState(true);
  const { calcEndUserMarkup, calcDealerMarkup, calcWholesalerMarkup } =
    useMarkups(markupData);

  // Sort vendors based on discount if checkbox is checked
  const vendors = sortByDiscount
    ? [...vendorsData].sort((a, b) => {
        const discountA = !!a.Discount
          ? a.Discount
          : !!a.AvgDiscount
          ? a.AvgDiscount
          : 99;
        const discountB = !!b.Discount
          ? b.Discount
          : !!b.AvgDiscount
          ? b.AvgDiscount
          : 99;
        return discountA === discountB
          ? a.Vendor.localeCompare(b.Vendor)
          : discountA - discountB;
      })
    : vendorsData;

  const columns = [
    "Vendor",
    "Discount",
    "EndUser",
    "Dealer",
    "Wholesaler",
    "Min Order",
  ];

  // Helper function to get either the markup/variance or the price/variance
  const getMarkupOrPrice = (
    tier,
    discount,
    vendorMarkup,
    standardMarkup,
    showVariance,
    showPricing
  ) => {
    if (!discount) {
      return showVariance ? <i>n/a</i> : vendorMarkup.toFixed(2);
    }

    const ListPrice = 100; // Fixed price for the item
    const cost = ListPrice * (1 - discount); // Discounted cost
    const vendorPrice = cost * vendorMarkup; // Final price with vendor markup
    const standardPrice = cost * standardMarkup;

    if (showPricing) {
      if (showVariance) {
        const priceDifference = vendorPrice - standardPrice;
        const className =
          priceDifference > 0
            ? pre("variance-positive")
            : priceDifference < 0
            ? pre("variance-negative")
            : "";
        return <span className={className}>{priceDifference.toFixed(2)}</span>;
      }
      return vendorPrice.toFixed(2); // Show the vendor's calculated price
    }

    if (showVariance) {
      const variance = vendorMarkup - standardMarkup;
      const className =
        variance > 0
          ? pre("variance-positive")
          : variance < 0
          ? pre("variance-negative")
          : "";
      return <span className={className}>{variance.toFixed(2)}</span>;
    }

    return vendorMarkup.toFixed(2); // Show the vendor markup if no pricing/variance
  };

  // Render Vendor Row
  const renderVendorRow = (vendor) => {
    const discount = vendor.Discount ?? vendor.AvgDiscount;
    const discountDefined = discount != null;
    const standardEndUserMarkup = discountDefined
      ? calcEndUserMarkup(discount)
      : null;
    const standardDealerMarkup = discountDefined
      ? calcDealerMarkup(discount)
      : null;
    const standardWholesalerMarkup = discountDefined
      ? calcWholesalerMarkup(discount)
      : null;

    return (
      <div className={pre("row")} key={vendor.Vendor}>
        <div>{vendor.Vendor}</div>
        <div>{(discount * 100).toFixed(2)}%</div>
        <div>
          {getMarkupOrPrice(
            "endUser",
            discount,
            vendor.EnduserMarkup,
            standardEndUserMarkup,
            showVariance,
            showPricing
          )}
        </div>
        <div>
          {getMarkupOrPrice(
            "dealer",
            discount,
            vendor.DealerMarkup,
            standardDealerMarkup,
            showVariance,
            showPricing
          )}
        </div>
        <div>
          {getMarkupOrPrice(
            "wholesaler",
            discount,
            vendor.WholesalerMarkup,
            standardWholesalerMarkup,
            showVariance,
            showPricing
          )}
        </div>
        <div>{vendor.MinOrder}</div>
      </div>
    );
  };

  const checkboxes = [
    {
      label: "Show pricing for $100 item",
      checked: showPricing,
      onChange: (e) => setShowPricing(e.target.checked),
    },
    {
      label: "Sort by Discount",
      checked: sortByDiscount,
      onChange: (e) => setSortByDiscount(e.target.checked),
    },
    {
      label: "Show variance from pricing guidelines",
      checked: showVariance,
      onChange: (e) => setShowVariance(e.target.checked),
    },
  ];

  const handleContainerDoubleClick = () => {
    setShowPricing((prev) => !prev);
  };

  return (
    <ReviewMarkupsBase
      title="Review Vendor Markups"
      returnUrl="/"
      data={vendors}
      columns={columns}
      renderVendorRow={renderVendorRow}
      checkboxes={checkboxes}
      containerClass="vendors"
      onContainerDoubleClick={handleContainerDoubleClick}
    />
  );
};

export default VendorMarkups;
