import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/ReviewMarkups.css"; // Import the CSS file where your styles are defined

const ReturnPrevious = ({ url }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url ? url : -1); // Go back to the previous page
  };

  return (
    <div className="return-previous" onClick={handleClick}>
      Return
    </div>
  );
};

export default ReturnPrevious;
