import { useState } from "react";

const useMarkups = (markupData) => {
  const [markups] = useState(markupData);

  const interpolate = (x, x0, x1, y0, y1) => {
    return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
  };

  const findClosestEntries = (discount) => {
    if (discount <= markups[0].discount) {
      return { lower: markups[0], upper: markups[0] };
    }
    if (discount >= markups[markups.length - 1].discount) {
      return {
        lower: markups[markups.length - 1],
        upper: markups[markups.length - 1],
      };
    }

    for (let i = 0; i < markups.length - 1; i++) {
      if (
        markups[i].discount <= discount &&
        markups[i + 1].discount >= discount
      ) {
        return { lower: markups[i], upper: markups[i + 1] };
      }
    }

    return {
      lower: markups[0],
      upper: markups[markups.length - 1],
    }; // Fallback case
  };

  const calcEndUserMarkup = (discount) => {
    const { lower, upper } = findClosestEntries(discount);

    const markup =
      discount <= lower.discount
        ? lower.endUserMarkup
        : discount >= upper.discount
        ? upper.endUserMarkup
        : interpolate(
            discount,
            lower.discount,
            upper.discount,
            lower.endUserMarkup,
            upper.endUserMarkup
          );

    return markup;
  };

  const calcDealerMarkup = (discount) => {
    const { lower, upper } = findClosestEntries(discount);

    const markup =
      discount <= lower.discount
        ? lower.dealerMarkup
        : discount >= upper.discount
        ? upper.dealerMarkup
        : interpolate(
            discount,
            lower.discount,
            upper.discount,
            lower.dealerMarkup,
            upper.dealerMarkup
          );
    return markup;
  };

  const calcWholesalerMarkup = (discount) => {
    const { lower, upper } = findClosestEntries(discount);

    const markup =
      discount <= lower.discount
        ? lower.wholesalerMarkup
        : discount >= upper.discount
        ? upper.wholesalerMarkup
        : interpolate(
            discount,
            lower.discount,
            upper.discount,
            lower.wholesalerMarkup,
            upper.wholesalerMarkup
          );
    return markup;
  };

  const calcEndUserPrice = (list, discount) => {
    const endUserMarkup = calcEndUserMarkup(discount);
    const cost = calcCost(list, discount);
    const endUserPrice = Math.max(list, cost * endUserMarkup);
    return endUserPrice.toFixed(2);
  };

  const calcDealerPrice = (list, discount) => {
    const dealerMarkup = calcDealerMarkup(discount);
    const cost = calcCost(list, discount);
    const dealerPrice = cost * dealerMarkup;
    return dealerPrice.toFixed(2);
  };

  const calcWholesalerPrice = (list, discount) => {
    const wholesalerMarkup = calcWholesalerMarkup(discount);
    const cost = calcCost(list, discount);
    const wholesalerPrice = cost * wholesalerMarkup;
    return wholesalerPrice.toFixed(2);
  };

  const calcCost = (list, discount) => {
    const discountRate = 1 - parseFloat(discount);
    return list * discountRate;
  };

  const calcListPrice = (cost, discount) => {
    const discountRate = 1 - parseFloat(discount);
    if (discountRate === 0) {
      throw new Error("Discount rate cannot be zero");
    }
    return cost / discountRate;
  };

  const calcMargin = (discount) => {
    const margin = 1 / (1 - parseFloat(discount)) - 1;
    return margin;
  };

  const calculatePrices = (cost, discount) => {
    if (!discount || discount < 0)
      return {
        listPrice: 0,
        endUserPrice: 0,
        dealerPrice: 0,
        wholesalerPrice: 0,
        endUserMarkup: 1,
        dealerMarkup: 1,
        wholesalerMarkup: 1,
      };

    const listPrice = calcListPrice(cost, discount);

    return {
      listPrice: listPrice,
      endUserPrice: calcEndUserPrice(listPrice, discount),
      dealerPrice: calcDealerPrice(listPrice, discount),
      wholesalerPrice: calcWholesalerPrice(listPrice, discount),
      endUserMarkup: calcEndUserMarkup(discount),
      dealerMarkup: calcDealerMarkup(discount),
      wholesalerMarkup: calcWholesalerMarkup(discount),
    };
  };

  return {
    calcEndUserMarkup,
    calcDealerMarkup,
    calcWholesalerMarkup,
    calcEndUserPrice,
    calcDealerPrice,
    calcWholesalerPrice,
    calcCost,
    calcListPrice,
    calcMargin,
    calculatePrices,
  };
};

export default useMarkups;
