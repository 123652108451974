import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PartPricing from "./PartPricing";
import VendorMarkups from "./VendorMarkups";
import PartstownMarkups from "./PartstownMarkups";
import PricingTable from "./PricingTable";
import Invite from "../auth/Invite";
import Unauthorized from "../auth/Unauthorized"; // Your Unauthorized component
import "../styles/styles.css";
import "../styles/auth.css";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/invite" element={<Invite />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/partstown-markups" element={<PartstownMarkups />} />
          <Route path="/vendor-markups" element={<VendorMarkups />} />
          <Route path="/pricing" element={<PricingTable />} />
          <Route path="/" element={<PartPricing />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
