const markups = [
  {
    discount: 0,
    endUserMarkup: 1.3,
    dealerMarkup: 1.25,
    wholesalerMarkup: 1.2,
  },
  {
    discount: 0.2,
    endUserMarkup: 1.4,
    dealerMarkup: 1.3,
    wholesalerMarkup: 1.25,
  },
  {
    discount: 0.3,
    endUserMarkup: 1.5,
    dealerMarkup: 1.4,
    wholesalerMarkup: 1.3,
  },
  {
    discount: 0.4,
    endUserMarkup: 1.7,
    dealerMarkup: 1.425,
    wholesalerMarkup: 1.35,
  },
  {
    discount: 0.5,
    endUserMarkup: 2,
    dealerMarkup: 1.45,
    wholesalerMarkup: 1.375,
  },
  {
    discount: 0.6,
    endUserMarkup: 2.5,
    dealerMarkup: 1.5,
    wholesalerMarkup: 1.4,
  },
  {
    discount: 0.7,
    endUserMarkup: 3,
    dealerMarkup: 1.6,
    wholesalerMarkup: 1.5,
  },
  {
    discount: 0.75,
    endUserMarkup: 3,
    dealerMarkup: 1.8,
    wholesalerMarkup: 1.6,
  },
  {
    discount: 0.8,
    endUserMarkup: 3,
    dealerMarkup: 2,
    wholesalerMarkup: 1.75,
  },
  {
    discount: 1,
    endUserMarkup: 3,
    dealerMarkup: 2,
    wholesalerMarkup: 1.75,
  },
];

export default markups;
