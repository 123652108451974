import React from "react";
import { useNavigate } from "react-router-dom";
import InviteCreate from "./InviteCreate";
import InviteAccept from "./InviteAccept";

function Invite() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  if (token) {
    // If a token is present, render the invite acceptance component
    return <InviteAccept token={token} navigate={navigate} />;
  } else {
    // Otherwise, render the invite creation component
    return <InviteCreate navigate={navigate} />;
  }
}

export default Invite;
