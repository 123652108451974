import React, { useState } from "react";
import "../styles/ReviewMarkups.css";
import useMarkups from "../components/useMarkups";
import markupData from "../data/markups";
import ReturnPrevious from "../components/ReturnPrevious";
import MarkupChart from "../components/MarkupChart";

const pre = (c) => "review-markups__" + c;

const PricingTable = () => {
  const [increment, setIncrement] = useState(1);
  const RefPrice = 100;

  const { calcEndUserMarkup, calcDealerMarkup, calcWholesalerMarkup } =
    useMarkups(markupData);

  // Calculate the number of discount steps based on the selected increment
  const Count = Math.floor(90 / increment) + 1;

  // Generate the discounts array based on the selected increment
  const discounts = Array.from(
    { length: Count },
    (_, i) => (i * increment) / 100
  );

  return (
    <div className={pre("container")}>
      <div className="alert">
        <strong>ATTENTION:</strong>&ensp;Case Parts internal use only!
      </div>
      <h1>
        Review Markups and Prices by Discount <ReturnPrevious />
      </h1>
      <p>
        <i>Prices reflect purchase of an item with a ${RefPrice} MSRP</i>
      </p>
      <MarkupChart markups={markupData} />
      {/* Increment Select Box */}
      <div>
        <label>Increment: </label>
        <select
          value={increment}
          onChange={(e) => setIncrement(Number(e.target.value))}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="5">5</option>
          <option value="10">10</option>
        </select>
      </div>
      <div className={pre("container pricing")}>
        {/* Header Row */}
        <div className={pre("header")}>
          <div>Discount</div>
          <div>Cost</div>
          <div>
            End&nbsp;User
            <br />
            Markup
          </div>
          <div>
            Dealer
            <br />
            Markup
          </div>
          <div>
            Wholesaler
            <br />
            Markup
          </div>
          <div>
            End&nbsp;User
            <br />
            Price
          </div>
          <div>
            Dealer
            <br />
            Price
          </div>
          <div>
            Wholesaler
            <br />
            Price
          </div>
        </div>
        {/* Discount Rows */}
        {discounts.map((discount, index) => {
          const cost = (1 - discount) * RefPrice;
          const endUserPrice = (cost * calcEndUserMarkup(discount)).toFixed(2);
          const dealerPrice = (cost * calcDealerMarkup(discount)).toFixed(2);
          const wholesalerPrice = (
            cost * calcWholesalerMarkup(discount)
          ).toFixed(2);

          return (
            <React.Fragment key={index}>
              <div className={pre("row")}>
                <div>{(discount * 100).toFixed(0)}%</div>
                <div>${cost.toFixed(2)}</div>
                <div>{calcEndUserMarkup(discount).toFixed(2)}</div>
                <div>{calcDealerMarkup(discount).toFixed(2)}</div>
                <div>{calcWholesalerMarkup(discount).toFixed(2)}</div>
                <div className={endUserPrice > 100 ? pre("over100") : ""}>
                  ${endUserPrice}
                </div>
                <div className={dealerPrice > 100 ? pre("over100") : ""}>
                  ${dealerPrice}
                </div>
                <div className={wholesalerPrice > 100 ? pre("over100") : ""}>
                  ${wholesalerPrice}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PricingTable;
